<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'japps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card
            :user-data="userData"
            :sales-persons="salesPersons"
          />
        </b-col>
        <!--
          TO DO: altre informazioni utente per utente
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        -->
        <!--
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-permissions-card
            v-if="$can('read', 'Permessi')"
            :current-role="currentRole"
          />
        </b-col>
        -->
      </b-row>

      <!-- <invoice-list /> -->
    </template>

  </div>
</template>

<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
// import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    // UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    // InvoiceList,
  },
  setup() {
    const userData = ref(null)
    const roles = ref(null)
    const currentRole = ref(null)
    const roleOptions = ref(null)
    const salesPersons = ref([])

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      console.log("on mounterd")
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data
          // store.dispatch('app-user/fetchRoles', { })
          //   .then(res => {
          //     try {
          //       roles.value = res.data
          //       /*
          //       console.log("roles.value", roles.value.roles)
          //       if (userData.value.roles.name) {
          //         userData.value.roles = roles.value.roles.find(x => x.name == 'admin')[0]
          //       }
          //       */

          //       currentRole.value = roles.value.roles.filter(x => x.name == userData.value.roles.name)[0]
          //       roleOptions.value = roles.value.roles.map(role => ({ label: role.name[0].toUpperCase() + role.name.slice(1), value: role.name }))
          //       console.log(currentRole.value.abilities)
          //       Array.from(currentRole.value.abilities).forEach(role => {
          //         console.log(role)
          //         const actions = Object.keys(role)
          //         const { subject } = role
          //         actions.splice(actions.findIndex(item => item === 'subject'), 1)
          //         console.log(actions)
          //         Array.from(actions).forEach(action => {
          //           console.log(action)
          //           role[action] = userData.value.abilities.filter(x => x.action == action && x.subject == subject).length > 0
          //         })
          //       })
          //     } catch (e) {
          //       console.log('e-err', e)
          //     }
          //   })
          //   .catch(error => {
          //     if (error.response.status === 404) {
          //       roles.value = undefined
          //     }
          //   })
          store.dispatch('app-user/fetchRoles', { })
            .then(res => {
              roles.value = res.data
              currentRole.value = roles.value.roles.filter(x => x.name == userData.value.roles.name)[0]
              roleOptions.value = roles.value.roles.map(role => ({ label: role.name[0].toUpperCase() + role.name.slice(1), value: role.name }))
              console.log(currentRole.value.abilities)
              Array.from(currentRole.value.abilities).forEach(role => {
                console.log(role)
                const actions = Object.keys(role)
                const { subject } = role
                actions.splice(actions.findIndex(item => item === 'subject'), 1)
                console.log(actions)
                Array.from(actions).forEach(action => {
                  console.log(action)
                  role[action] = userData.value.abilities.filter(x => x.action == action && x.subject == subject).length > 0
                })
              })
            })
            .catch(error => {
              if (error.response.status === 404) {
                roles.value = undefined
              }
            })
        }).catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    })
    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    store.dispatch('app-user/fetchSalesPerson', { })
      .then(res => {
        console.log(res.data)
        salesPersons.value = res.data.oslp
      })
      .catch(error => {
        if (error.response.status === 404) {
          salesPersons.value = undefined
        }
      })

    return {
      userData,
      roleOptions,
      currentRole,
      roles,
      salesPersons,
    }
  },
}
</script>

<style>

</style>
