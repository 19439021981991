<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="currentRole.abilities"
      class="mb-0"
    >
      <template #head(subject)>
        <div class="text-nowrap" />
      </template>
      <template #cell(subject)="" />
      <template #cell(name)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '../userStoreModule'
import store from '@/store'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    currentRole: {
      required: true,
    },
  },
  setup() {
    const roles = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchRoles', { })
      .then(response => { roles.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          roles.value = undefined
        }
      })

    return {
      roles,
    }
  },
}
</script>

<style>

</style>
